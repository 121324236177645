@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');
/* @import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600&display=swap"); */

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400; 
  font-style: normal;
}

body, .p-component, input{
  font-family: 'Poppins', Arial, sans-serif !important;
}
body {
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  overflow-x: hidden;
}
.p-dialog .p-dialog-content{
  padding: 0 1.5rem 2rem 1.5rem !important;
}
.p-toast-message-content {
  padding: 1rem !important;
}
.p-toast-detail, .p-toast-summary{
  padding-left: 10px !important;
}
.p-toast.p-toast-top-right {
  top: 55px !important;
}

/* ul {
   padding-left: 0px !important;
} */
.p-dropdown-panel{
  font-size: 14px !important;
}
* {
  box-sizing: border-box;
}

.text-10 {
  font-size: 10px;
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 14px !important;
}

.text-16 {
  font-size: 16px;
}

.text-18 {
  font-size: 18px;
}

.text-19 {
  font-size: 19px;
}

.text-20 {
  font-size: 20px;
}

.text-22 {
  font-size: 22px;
}

.text-24 {
  font-size: 24px;
}

.text-26 {
  font-size: 26px;
}

.text-30 {
  font-size: 30px;
}

.text-40 {
  font-size: 40px;
}
.text-xs{
  font-size: 0.75rem;

}
.text-sm{
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.show-cursor {
  cursor: pointer;
}
.hide-cursor{
  cursor: default !important;
}
.sonant-switch .p-inputswitch-input{
  cursor: default !important;
}
/* header css start */
.pf-header-row {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  width: 100%;
  background-color: #381574e6;
  backdrop-filter: blur(10px) brightness(90%) contrast(120%) saturate(150%);
}

.mobile-view-header-menu img {
  filter: invert(100%);
}
.header-ig-logo {
  height: 48px;
}

.header-momentum-logo {
  width: 180px;
  height: 31px;
}

.header-span {
  cursor: pointer;
  display: flex !important;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  color: #fff;
  text-wrap: nowrap;
}

/* .header-span.active,
.mb-menu.active {
  filter: hue-rotate(230deg);
} */

.header-span img {
  width: 20px;
  height: 20px;
}

.add-apps-loader {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 350px !important;
}

.p-overlaypanel {
  min-height: 350px !important;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12) !important;
}

.commission-feedback-panel.p-overlaypanel {
  min-height: unset !important;
}

/* header css end */
.validation-div .css-1o07z2d,
.stripe-validation {
  border-color: #dc3545 !important;
}

textarea.validation-div, .validation-div .finance-company-dropdown {
  border-color: #dc3545 !important;
}

textarea:focus {
  box-shadow: none !important;
  outline: none !important;
}

.highlight-div .css-1o07z2d {
  border-color: #a3d661 !important;
  background-color: #f0ffdb;
}

.fenris-logo {
  height: 30px;
  margin-right: 10px;
}

.content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-height: 89.7vh; */
  height: 99vh !important;
  overflow-y: scroll;
  padding-top: 3%;
}
.background-video{
  background-image: linear-gradient(#8854bc,#a200ff,#381576);
}
.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  z-index: -1;
  object-fit: cover;
}

.wrapper-main-row {
  display: flex;
  align-items: center;
  /* min-height: 75vh; */
  /* height: 75vh; */
  width: 100%;
  padding: 10px 30px;
}

.main-content-row {
  /* min-height: 75vh; */
  height: 75vh;
  display: flex;
}

.sidebar-col {
  border-radius: 8px 0px 0px 8px;
  flex: 2;
}

.main-content-col {
  flex: 10;
  border-radius: 0px 8px 8px 0px;
}

.admin-main-page .client-search-row {
  border-radius: 5px 5px 0px 0px !important;
}

.admin-main-page .history-table {
  border-radius: 0px 0px 5px 5px !important;
}

.admin-main-page .main-content-col {
  border-radius: 5px !important;
}

/* sidebar css start */
.pf-sidebar-menu {
  font-size: 14px;
  display: flex;
  padding-left: 20px !important;
  padding: 10px;
  align-items: center;
  cursor: pointer;
}

.pf-sidebar-img {
  height: 22px;
  width: 25px;
  padding-right: 5px;
}

.coming-soon-block {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.coming-soon-title {
  text-align: center;
  font-weight: bold;
}
.coming-soon-banner{
  border: 1px solid #381576;
  border-radius: 20px;
  padding: 5px; 
  text-align: center;
  color: #381576; 
}
/* sidebar css end */

/* middle header css start */
.middle-header-logo {
  height: 30px;
  width: 30px;
}

.middle-header-col {
  border-radius: 10px;
  padding: 10px 7px;
  display: inline-block;
  margin-right: 10px;
  /* width: 13%; */
  margin-bottom: 10px;
}

.middle-header-col.active {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 7px;
  margin-bottom: 0;
  border-radius: 10px 10px 0px 0px;
}

.middle-header-title {
  font-size: 14px;
  margin-left: 5px;
  text-wrap: nowrap;

}

/* middle header css end */
/* main content css start */
.no-primary-ams {
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.client-search-row,
.history-search-row {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  border-top-right-radius: 8px;
}

.search-bar {
  display: flex;
}

.search-area {
  border: 1px solid;
  width: 263px;
  height: 32px;
  padding-left: 5px;
}

.clientname-placeholder input#client-name::placeholder, 
.insured-placeholder input#naics-client-name::placeholder {
  color: #000 !important;
}
.carrier-placeholder input#carrier-name::placeholder {
  color: #c5c5c5 !important;
}
.search-area input::placeholder,
.comment-text::placeholder {
  font-size: 12px !important;
}

.search-btn {
  border-radius: 1px 10px 10px 1px !important;
  font-size: 14px !important;
  height: 2.19em !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.pf-secondary-btn {
  border-radius: 8px !important;
}

.save-btn {
  border-radius: 8px !important;
  margin-right: 2px !important;
}

.pf-primary-btn,
.pf-danger-btn, 
.pf-secondary-btn {
  border-radius: 8px !important;
  font-size: 12px !important;
}
.pf-primary-btn span, .pf-secondary-btn span{
  font-weight: normal;
}
.ai-feedback-btn{
  background-color: #6b46c1 !important;
  border-radius: 1rem !important;
}
.ai-feedback-btn span {
  color: #fff;
  font-size: 14px;
}
.pay-monthly-btn span {
  color: #000;
}
.show-direct-btn{
  background-color: #381576 !important;
}
.show-direct-btn span{
  color: #fff;
}
.history-response-btns .pf-primary-btn,
.setting-btn-position .pf-primary-btn {
  border-radius: 8px !important;
}

.history-response-btns .pf-primary-btn:hover,
.setting-btn-position .pf-primary-btn:hover {
  border-radius: 8px !important;
}

.update-client-btn-div,
.client-search-btn-col {
  display: flex;
  align-items: center;
}
.landing-heading-text-color{
  color:  #381574;
  font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
}
.landing-page-blocks {
  border-radius: 8px;
  /* padding: 10px; */
  padding-bottom: 0px;
  width: 92%;
  /* margin-bottom: 10px; */
  min-height: 180px;
  align-items: center;
  background-color: #fff;
  /* margin-bottom: 35px; */
  transition: transform 0.3s;
  text-align: justify;
}

.landing-page-blocks:hover {
  transform: translateY(-10px);
}

.landing-page-img {
  height: 50px;
  width: 50px;
  margin-left: 8px;
  margin-right: 8px;
}

.info-img {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}

.landing-page-addapp-img {
  text-align: center;
}

.landing-page-title2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
  /* margin-left: 10px; */
}

.landing-info-position {
  text-align: end;
}

.article-title-text {
  padding-left: 20px;
  border-left: 4px solid #47127d;
  margin-top: 10px;
}

.mid-container {
  max-width: 92%;
  margin: 0 auto;
}
.landing-content-wrapper {
  display: block;
  /* padding: 2%; */
}
.landing-block-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 10px;
  cursor: pointer;
  z-index: 1;
}

.landing-block-menu img {
  margin-bottom: 5px;
  height: 25px;
  width: 25px;
}

.finance-company-dropdown {
  width: 100%;
  height: 30px !important;
}

.finance-company-dropdown span.p-dropdown-label.p-inputtext.p-placeholder,
.finance-company-dropdown span.p-dropdown-label.p-inputtext {
  font-size: 12px;
  padding: 8px;
}

.finance-company-dropdown .p-icon {
  height: 12px;
  width: 12px;
}
.finance-company-dropdown.p-multiselect .p-multiselect-label{
  padding: 5px !important;
}

.billing-payment-history .p-datatable .p-datatable-thead > tr > th,
.billing-payment-history .p-datatable .p-datatable-tbody > tr > td {
  font-size: 14px;
}

.billing-payment-history tbody td {
  padding: 4px 5px 4px 10px !important;
  height: 30px !important;
}

.input-field input {
  height: 30px !important;
  font-size: 12px;
}

/* datatable css start */
.admin-main-page .history-table .p-datatable-selectable .p-selectable-row {
  cursor: default !important;
}

.history-unavailable {
  display: flex;
  justify-content: center;
  align-items: center;
}

.history-table {
  width: 100%;
  overflow-x: auto;
  font-size: 12px;
}

.history-table table {
  margin-bottom: 10px;
}

.history-table thead.p-datatable-thead tr {
  font-size: 12px;
}

.history-table .p-datatable > .p-datatable-wrapper {
  overflow: visible;
}

.history-table thead.p-datatable-thead th {
  padding: 5px !important;
  border-bottom: none !important;
}

.history-table .p-column-header-content {
  margin: 2px;
}

.sent-to-ams-icon {
  border-radius: 55%;
  padding: 4px;
  margin-right: 5px;
  font-size: 10px !important;
}
.finch-success-icon{
  border-radius: 55%;
  padding: 6px;
  margin-right: 5px;
  font-size: 40px !important;
  background: #1ab394;
  color: #fff;
}
.finch-icon-bg{
    padding: 5px;
    border-radius: 50%;
    font-size: 16px !important;
}
.vehicle-classification-criteria-info {
  cursor: pointer;
}

.indicative-icon {
  border-radius: 55%;
  padding: 3px 7px;
  margin-right: 5px;
  font-size: 10px !important;
}

.history-table tbody {
  font-size: 12px;
}

tr.p-datatable-emptymessage td {
  text-align: center !important;
}

.history-table .p-datatable .p-datatable-tbody > tr > td {
  padding: 7px;
}

.expander-height button {
  height: 20px !important;
  width: 20px !important;
  padding: 4px !important;
}

/* datatable css end */
.pf-company-col {
  border-radius: 10px;
  flex: 12;
  margin-bottom: 10px;
}

.pf-setting-inputs {
  padding: 7px;
  font-size: 12px;
}

.pf-setting-title {
  padding: 10px;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: #343a40;
  padding: 0.5rem !important;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.counter {
  float: right;
}

.counter-color {
  font-weight: 700;
}

.feedback-panel,
.addapps-panel {
  width: 300px;
}

.premfi-scan-confirm-msg-panel {
  width: 25%;
  min-height: 70px !important;
}

.email-panel {
  width: 400px;
  min-height: 106px !important;
}

.email-text {
  font-size: 12px !important;
}

.feedback-panel .p-overlaypanel-content,
.addapps-panel .p-overlaypanel-content,
.email-panel .p-overlaypanel-content {
  padding: 0 !important;
}

.feedback-header {
  padding: 10px;
  margin-bottom: 10px;
}
.feedback-header h4{
  font-size: 1rem !important;
}
.add-apps-div {
  display: flex;
  align-items: center;
}

.add-apps-border {
  border-bottom: 2px solid beige;
}

.menu-panel {
  min-height: 70px !important;
  min-width: 100px;
}

.bookmarked-icon span {
  cursor: pointer;
}

.bookmark-icon span {
  cursor: pointer;
}

.ams-notselected-icon {
  cursor: pointer;
}

.ams-selected-icon {
  cursor: pointer;
}

.primary-ams-main-block {
  border-radius: 10px;
}

.common-setting-block {
  border-radius: 10px;
  padding-bottom: 35px;
}

.gigcode-block {
  border-radius: 10px;
  margin-top: 10px;
}

.height-large {
  min-height: 300px;
  position: relative;
}

.height-small {
  height: 320px;
  position: relative;
}

.more-block-row {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.ams-header {
  padding: 8px;
  font-weight: normal;
}

.bookmark-history-icon-after-save {
  font-size: 20px;
  cursor: pointer;
}

.hawksoft-settings-eye-icon,
.nc-eye-icon {
  position: absolute;
  top: 30px;
  right: 20px;
}

.login-eye-icon {
  position: absolute;
  top: 5px;
  right: 20px;
}

.more-block {
  padding: 5px 10px;
  border-radius: 0px 0px 10px 10px;
}

.more-block img {
  cursor: pointer;
}

.login-box {
  width: 28%;
  padding: 40px 15px;
  border-radius: 10px;
}

.register-box {
  border-radius: 10px;
  width: 28%;
  min-height: 300px;
}

.registration-related-link {
  color: #0052cc;
  text-decoration: underline;
  cursor: pointer;
}

.terms-box {
  width: 50%;
  margin: 10px 30px;
  padding: 40px 15px;
  border-radius: 10px;
}

.social-link-block {
  cursor: pointer;
}

.google-box {
  text-align: end;
}

.social-link-block img {
  height: 18px;
  width: 18px;
}

.social-link-block:hover {
  color: #00add9;
  text-decoration: underline;
}

.login-link {
  letter-spacing: 1px;
}

.finance-company-name {
  font-size: 12px;
  margin-bottom: 5px;
  display: flex !important;
  align-items: center;
  padding: 2px;
}

.setting-btn-position {
  position: absolute;
  width: 100%;
  bottom: 5px;
}

#client-search {
  position: absolute;
  width: 100%;
  height: fit-content;
  max-height: 200px;
  min-height: 35px;
  margin-top: 0px;
  overflow: auto;
  border-radius: 0 0 5px 5px;
  z-index: 999;
}

#client-search > div {
  min-height: 35px;
  padding: 5px;
  display: flex;
  align-items: center;
  transition: 0.1s background;
}

.client-loader-div {
  border-bottom: none !important;
}

.canopy-clientselector-dropdown {
  position: absolute;
  width: calc(100% - 25px);
  max-height: 200px;
  min-height: 35px;
  margin-top: 0px;
  overflow: auto;
  border-radius: 0 0 5px 5px;
  z-index: 999;
}

.client-search-width {
  top: 30px;
}

.canopy-clientselector-dropdown > div {
  min-height: 35px;
  padding: 5px;
  display: flex;
  align-items: center;
  transition: 0.1s background;
}
.carrier-link{
  color: black;
  width: 100%;
  font-size: 12px;
  line-height: 18px;
}
.pf-setting-col {
  border-radius: 10px;
  min-height: 330px;
  position: relative;
}

.finance-company-name .p-inputswitch {
  height: 25px !important;
}

.effective-data-span {
  display: inline-block !important;
  margin-left: 25px;
}

.policy-type-heading {
  font-weight: bold;
  font-size: 14px;
}
.client-name-span,.policy-back-btn span{
  font-size: 14px;
}
.divider {
  padding-top: 10px;
}

.accept-checkbox .css-136kakq-Checkbox {
  display: flex;
  align-items: center;
}

.total-premium-div {
  font-size: 14px;
  font-weight: bold;
  border-radius: 6px;
  padding: 6px;
  width: 100%;
}

.subpolicy-block label,
.showmore-block label {
  margin-bottom: 3px;
}

.subpolicy-block input,
.showmore-block input {
  font-size: 12px !important;
}

.authenticating-block-pf {
  border-radius: 8px;
  padding: 10px;
  font-size: 24px;
  text-align: center !important;
}

.not-authorize-text {
  font-size: 24px;
}

.policy-name-tab {
  margin-top: 10px;
  padding: 6px 15px 8px 15px;
}

.policy-type {
  border: none !important;
}

.policy-name-tab span {
  border-bottom: none !important;
  padding: 12px 15px;
  border-radius: 5px 5px 0px 0px;
  font-weight: bold;
}

.policy-name-span {
  position: relative;
}

.remove-policy-icon {
  position: absolute;
  top: -2px;
  right: 3px;
  font-weight: 700;
  cursor: pointer;
}

.policydetails input::placeholder {
  color: #000 !important;
}

.policy-name-span.active {
  padding: 13px 15px;
}
.summary-compare-selection{
  width: 30px;
  height: 20px;
  cursor: pointer;
}
.quote-response-block {
  border-radius: 10px;
}

.quote-middle-block {
  min-height: 227px;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.quote-heading {
  padding: 10px;
  border-radius: 10px 10px 0px 0px;
  margin-top: 0 !important;
}

.response-btn-div {
  width: 100%;
  padding: 10px;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  justify-content: space-evenly;
}

.history-expanded-block {
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.expanded-row-button-block {
  border-radius: 0px 10px 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.expanded-row-button-block button {
  width: 100% !important;
  margin-bottom: 10px !important;
}

.final-quotes-table td {
  padding: 5px 10px !important;
}

.progress-bar {
  position: absolute;
  top: -60px;
}

.history-details-font {
  font-size: 12px;
}

.display-for-desktop {
  display: block !important;
}

.display-for-mobile {
  display: none !important;
}

.display-for-mobile-flex {
  display: none !important;
}

.display-for-desktop-flex {
  display: inline-flex !important;
}

.details-border {
  border-top: none;
}

.div-blur-loading {
  filter: blur(5px);
  pointer-events: none;
}

.response-btn-div .col-lg-12 {
  width: 50%;
}

.response-btn-div .pf-primary-btn {
  width: 90% !important;
}

.copyright-block {
  text-align: end;
}

.back-btn-div {
  flex: 0 0 !important;
}

.profile-box {
  border-radius: 10px;
  padding: 10px;
  min-height: 80vh;
  width: 98%;
  padding: 30px;
}

.profile-title {
  padding: 7px;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}

.change-password-new-eye-icon {
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 15%;
}

.change-password-confirm-eye-icon {
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 15%;
}

.profile-info-icon {
  font-size: 12px;
  padding: 10px;
}

.profile-text-area:focus-visible {
  border: none !important;
}

.setup-info-div {
  width: 40%;
  margin: 0 auto;
}

.get-started-div {
  margin-top: 10%;
  margin-bottom: 5%;
}

.started-btn {
  border-radius: 8px;
  padding: 10px 30px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.skip-link {
  font-size: 14px;
  cursor: pointer;
}

.setup-height-div {
  min-height: 81vh;
  margin: 0 auto;
  width: 100%;
}

.Ams-logo {
  padding: 10px;
}

.Ams-logo img {
  width: 100px;
}

.ams-name-span {
  font-weight: 500;
  font-size: 16px;
}

.wizard-ams-div {
  margin-bottom: 10px;
  padding: 10px;
}

.credentials-div {
  padding: 10px;
}

.credential-field-width {
  width: 40%;
}

.eye-icon {
  position: absolute;
  top: 55%;
  right: 21px;
  transform: translateY(-50%);
  cursor: pointer;
}

.widget-block {
  display: flex;
  align-items: center;
  padding: 10px 2px;
  font-weight: 600;
}

.select-widget-link {
  text-decoration: underline;
  margin-right: 20px;
  font-size: 12px;
  cursor: pointer;
}

.company-list-block {
  font-size: 14px;
}

.finance-company {
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-company {
  padding: 10px;
  margin-bottom: 10px;
}

.not-selected-company {
  padding: 10px;
  margin-bottom: 10px;
}

.select-company-hover:hover {
  background-color: #fff;
}

.setup-btn-position {
  position: absolute;
  bottom: 20px;
  right: 10px;
}

.setup-right-side {
  margin-left: 20px;
}

.company-list-setup-mainpage,
.company-setting-setup-mainpage {
  margin-bottom: 0.5rem;
}

.setting-setup-width {
  width: 50%;
}

.company-list-setup-mainpage,
.company-setting-setup-mainpage {
  min-height: 50vh;
}

.ams-agency-setup-main-page {
  margin-bottom: 10%;
}

.sso-loader {
  text-align: center;
}

.app-skeleton {
  min-height: 100vh !important;
}

.no-client-msg {
  align-items: center;
  display: flex;
  min-height: 75%;
  opacity: 0.5;
}

.company-name-div:hover {
  cursor: pointer;
}

.copy-icon {
  height: 16px;
}

textarea {
  resize: none !important;
}

.bundle-checkbox {
  width: 20px;
  height: 20px;
}

.add-policy-loding span {
  border: none !important;
  padding: 2px !important;
}

.add-policy-loding {
  border: none !important;
}

.register-header {
  border-bottom: 1px solid #d9d9d9;
}

.already-user-exist-message {
  text-align: center;
  font-size: 16px;
  padding: 5px 0px;
  background-color: #ffcdd2;
  border: 0 solid #e60017;
  color: #d01024;
  border-radius: 3px;
  width: 100%;
}

.agent-success-message {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 0px;
  background-color: #a0e0c3;
  border: 0 solid #00875a;
  color: #00875a;
  border-radius: 3px;
  width: 100%;
}

.success-box {
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.forgot-password-btn {
  position: absolute;
  bottom: 10px;
  text-align: center;
}



.pf-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.history-action-column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion__panel {
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.accordion__panel__invalid {
  border: solid 2px red;
}

.accordion__panel-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 4px;
  line-height: 20px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  transition: height 2000ms ease 0s;
  cursor: pointer;
}

.accordion__panel-title svg {
  margin-left: 10px;
}

.accordion__panel-content {
  padding: 10px;
  display: none;
}

.accordion__panel-content.active {
  display: block;
}

.sepearte-page-header-div {
  text-align: end;
}

.continue-google-div {
  text-align: end;
}

.admin-popup {
  padding: 8px;
  border-radius: 0 0 8px 8px;
  top: 0px;
  left: 45%;
  position: absolute;
  width: auto !important;
}

.p-inputswitch {
  margin: 5px !important;
}

.carrier-option-link {
  color: #000;
  width: 100%;
  font-size: 12px;
  line-height: 18px;
}

.humberger-btn {
  border: none;
  background-color: transparent !important;
}

.humberger-icon i {
  font-size: 16px;
  padding: 3px 6px;
  border-radius: 6px;
}


/* .wrapper-below-div {
  margin-top: 3% !important;
} */

.scan-search-row {
  background-color: #f6f6f6;
  padding: 15px 10px;
}
.css-1k5ez1w[data-disabled]:not([data-checked]) {
  background-color: #dadde3 !important;
}
.commission-feedback-icon {
  cursor: pointer;
  font-size: 25px !important;
  margin-right: 3px;
}

.fa-thumbs-up.commission-feedback-icon.active {
  color: #00875a;
}

.fa-thumbs-down.commission-feedback-icon.active {
  color: #b84c4c;
}

.basic-card-header {
  padding: 5px;
  text-align: center;
  font-size: 30px;
  letter-spacing: 2px;
}

.billing-card-header1 {
  color: #00b1cc;
}

.billing-card-header2 {
  color: #5f6072;
}

.billing-card-header3 {
  color: #e09199;
}

.subscription-card:hover {
  /* background-color: #b7b2d3;
  color: #ffffff; */
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
  0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12) !important;

}

/* rounded checkbox css start */
.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 10px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.service-card {
  background: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 15px;
  transition: box-shadow 0.3s ease;
  transition: transform 0.3s;
  /* text-align: center; */
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  min-height: 235px;
  box-shadow: 0 0 20px rgb(51 51 51 / 10%);
}

.service-card:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
  border: 1px solid #a100f9;
}

.ribbon {
  position: absolute;
  top: 10px; 
  right: -10px; 
  background-color: #cda01a; 
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  padding: 2px 7px;
  border-radius: 5px;
  z-index: 1;
}
.ribbon::after {
  content: '';
  position: absolute;
  bottom: -7px;
  right: 0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: #cda01a transparent transparent transparent; 
}
.app-star-ratings{
  display: flex;
  align-items: center;
  line-height: 20px;
}
.app-star-ratings span{
  height: 13px;
  width: 13px;
  color: #cda01a !important;
}
.user-count{
  background: #a100f9;
  padding: 3px 7px;
  color: #fff;
  border-radius: 4px;
}
.card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 12px; */
}

.service-logo {
  max-width: 60px;
  padding: 10px;
  height: 60px;
}

.service-title {
  font-size: 1.05rem;
  margin: 0;
  color: #381574;
}


.service-description {
  font-size: 0.8em;
  color: #381574;
  margin: 5px 0 15px 0;
  margin-bottom: 10px;
  /* text-align: justify; */
}

.card-buttons {
  display: flex;
  justify-content: end;
  margin-top: 10px;
  position: absolute;
  bottom: 15px;
  align-items: center;
}
.card-buttons-2{
  display: flex;
  justify-content: end;
  margin-top: 10px;
  position: absolute;
  bottom: 10px;
  right : 10px;
  font-size: 12px;
}
.landing-main-page .custom-spinner svg circle {
  stroke: #fff !important;
}
.landing-loader .custom-spinner span svg, .landing-loader .custom-spinner span {
  height: 45px !important;
  width: 45px !important;
}
/* rounded checkbox css ends */
.separator {
  display: flex;
  align-items: center;
}

.separator .line {
  height: 1px;
  background-color: #00875A;
}

.separator .line.left {
  width: 200px; 
  margin-right: 2rem; 
}
.separator .line.whole{
  width: 100%; 
  margin-bottom: 10px;
}
.separator span {
  font-size: 18px;
  color: #00875A;
  font-weight: 500;
}

.separator .line.right {
  flex-grow: 1; 
  margin-left: 2rem; 
}

.visible {
  visibility: visible !important;
  opacity: 1 !important;
  transition: opacity 0.5s ease-in-out;
  width: 50%;
}
.removevisible {
  visibility: hidden !important;
  opacity: 0 !important;
}
.custom-carousel.p-carousel .p-carousel-content .p-carousel-next, 
.custom-carousel.p-carousel .p-carousel-content .p-carousel-prev,
.platform-custom-carousel.p-carousel .p-carousel-content .p-carousel-prev,
.platform-custom-carousel .p-carousel-content .p-carousel-next {
    background: #fff !important;
    border-radius: 18px;
    margin-right: 5px;
    margin-left: 5px;
}
.custom-carousel .p-carousel-container {
  justify-content: center;
  display: flex;
}

.custom-carousel .p-carousel-item {
  flex: 1;
}
.p-carousel-indicator button{
  display: none !important;
}

.p-toast-message{
  margin-bottom: 5px !important;
}
.our-platform-description{
  font-size: 14px;
}
/* Scrollbar css starts */

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 30px;
}

::-webkit-scrollbar-thumb {
  background: #381576;
  border-radius: 30px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.ai-wrapper ::-webkit-scrollbar {
  width: 3px;
  height: 6px;
}
.ai-wrapper ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f8f9fe;
  border-radius: 30px;
}

.ai-wrapper ::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 30px;
}
/* Scrollbar css end */

.no-scroll{
  overflow: hidden;
}
.choose-file-box {
  border: 1px double #2dacc2;
  padding: 20px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-file-label2 {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  color: #2dacc2;
  border-radius: 4px;
  text-align: center;
  border: 1px solid;
}

.file-name-box {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.file-name {
  text-align: start;
  background: #a9dde6;
  padding: 4px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  /* display: flex;
  align-items: center; */
  min-height: 30px;
}

.policy-unselected-btn {
  color: #2dacc2 !important;
  border: 1px solid #2dacc2 !important;
  font-size: 12px !important;
}

.custom-file-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.custom-file-label {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  color: #fff;
  background-color: #2dacc2;
  border-radius: 4px;
  text-align: center;
}

.custom-file-label:hover {
  background-color: #2dacc2;
}

.css-1k5ez1w:focus-within {
  border: 2px solid #fff  !important;
}
.balance-span{
  border-radius: 4px;
  padding: 4px 12px;
  background-color: #deebff;
  font-weight: bold;
  border: 1px solid #abc4eb;
  flex-direction: column;
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 12px;
  }
  .balance-spinner .custom-spinner{
    height: 17px !important;
    width: 17px !important;
  }
  /* tabs css start */
  .tabs-container {
    border: 1px solid #ddd;
    border-radius: 5px;
    font-family: Arial, sans-serif;
    /* max-width: 500px; */
    margin: auto;
  }
  
  .tabs-header {
    display: flex;
    justify-content: space-around;
    background-color: #f5f5f5;
    /* padding: 8px; */
    border-bottom: 1px solid #ddd;
  }
  
  .tab-button {
    background: none;
    border: none;
    padding: 15px 20px;
    font-size: 20px;
    cursor: pointer;
    color: #555;
    transition: all 0.3s ease;
  }
  
  .tab-button:hover {
    color: #000;
  }
  
  .tab-button.active {
    font-weight: bold;
    color: #8d42ca;
    background: radial-gradient(circle, rgba(141,66,202,0.2805497198879552) 0%, rgba(245,245,245,1) 100%);
  }
  
  .tabs-content {
    padding: 20px;
  }
/* tabs css end */
.carousel-card {
  background-color: #fff; 
  border-radius: 16px; 
  transition: transform 0.3s, box-shadow 0.3s;
  width: 35%;
  min-height: 175px;
  display: flex;
  align-items: center;
}
.carousel-logo {
  width: 70px;
  height: auto;
}
.p-carousel-indicators{
  display: flex;
  align-items: center;
}
.p-carousel-indicators li{
  margin-top: 0px;
}
.rating span {
  display: inline-block;
  margin-right: 2px; 
}
.info-icon {
  height: 20px;
  cursor: pointer;
}
.custom-carousel .p-icon, .platform-custom-carousel .p-icon{
  color: #000;
}
.grid-search-icon{
  position: absolute;
  right: 10px;
  top: 55%;
  left: unset !important;
}
.landing-search{
  width: 35%;
  margin-top: 30px;
}
.p-dropdown-items, .p-multiselect-items,.p-multiselect-item {
  padding-left: 0 !important;
}
.p-multiselect-checkbox{
  padding-right: 5px;
  padding-left: 5px;
}
.payment-message p{
   font-size: 14px;
}

.p-multiselect-label.p-placeholder {
  font-size: 12px;
}
.p-multiselect-panel .p-multiselect-header{
  padding: 5px !important;
}
.ai-wrapper{
  /* background-color: #f1f5fe; */
  /* padding-top:2%; */
}
.ai-sidebar-menu:hover{
  background-color: #f3f4f6;
  cursor: pointer;
}
.ai-sidebar-menu.active{
background-color: #e5deff !important;
border-left: 3px solid #381574;
}
.ai-sidebar-menu{
  color: #000;
  }
.bg-momentum-light {
  background-color: #fff;
  border: 1px solid #e5e7eb;
  cursor: pointer;
}
.bg-momentum-light:hover {
  background-color: #f8f9fa;
}
.ai-question-block{
  padding: 10px;
  border-radius: 4px;
}

.ai-communication-icon{
  background-color: #fff !important;
  border-radius:  0 1rem 1rem 0  !important;
  
}

.ai-textarea{
  border: 1px solid #6b46c1 !important;

}
.ai-content-heading{
  background-color: #e5defd;
    padding: 10px 13px;
    border-radius: 50%;
    margin-right: 5px;
}
.ai-login-box{
 color: #381574;
}
.ai-new-conversion-btn:hover{
  background-color: #fff !important;
  color: #6b46c1 !important;
}
.ai-new-conversion-btn.active{
  background-color: #6b46c1 !important;
  color: #fff !important;
}
.answer-feedback-icon-box{
  padding: 7px;
}
.answer-feedback-icon-box:hover{
  background-color: #f9f9f9;
  border-radius: 8px;
}
.answer-feedback-icon-box:hover svg {
  stroke: #381574;
  
}
.ai-main-content-wrapper{
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  padding: 5px;
  border-radius: 1rem !important;
}
.ai-close-icon{
  position: absolute;
  right: 5px;
  cursor: pointer;
}
.drag-over {
  border: 2px dashed #381574 !important;
  background-color: rgba(56, 21, 116, 0.1) !important;
}

.drop-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e5defd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
  z-index: 10;
  pointer-events: none;
}
.ai-support-main-page{
  background-color: #f8f9fe;
}
.ai-border-radius{
  border-radius: 1rem !important;
}

/* main content css end */
/* media query start */
@media (max-width: 1550px) and (min-width: 1290px) {
  .ai-main-content-wrapper {
    height: 69vh !important;
  }
  .content-wrapper {
    padding-top: 5%;
  }
  .common-setting-main-page .content-wrapper {
    padding-top: 16%;
  }
  /* .wrapper-below-div {
    margin-top: 5% !important;
  } */

  /* .content-wrapper {
    padding-bottom: 4% !important;
  } */

  .pf-setting-col .pf-primary-btn {
    padding: 0 !important;
    font-size: 12px;
  }

  .register-box {
    width: 33%;
  }

  .pf-setting-col {
    min-height: 343px;
  }

  .landing-block-menu {
    font-size: 10px;
  }

  .profile-box {
    min-height: 76vh !important;
  }

  .response-btn-div {
    display: inline;
  }

  .ams-agency-setup-main-page {
    margin-bottom: 10%;
  }

  .credential-field-width {
    width: 50%;
  }

  .setup-height-div {
    min-height: 77vh;
  }

  .company-list-setup-mainpage {
    min-height: 50vh;
  }
}

@media (max-width: 1281px) and (min-width: 1024px) {
  .our-platform-description{
    font-size: 12px;
  }
  .sidebar-col{
 width:20% !important;
  }
  .main-content-col{
    width:80% !important;

  }
  .landing-page-card-col{
    width: 33% !important;
  }
 
  .landing-search{
    width: 55%;
  }
    .visible{
      width: 80% !important;
    }
  /* .coi-scan-middle-header-col, .middle-header-col{
    width: 26% !important;
  } */
 /* .balance-span{
  width: 40% !important;
 } */
 .content-wrapper {
  padding-top: 7%;
}
.common-setting-main-page .content-wrapper {
  padding-top: 25%;
}
.profile-main-page .content-wrapper {
  padding-top: 13%;
}
  /* .content-wrapper {
    padding-top: 7% !important;
    padding-bottom: 6% !important;
  } */
  .login-box ,.register-box{
    width: 45%;
  }
  .fenris-feedback-panel {
    max-width: 30% !important;
  }
  .carousel-logo {
    width: 55px;
}
  .ams-agency-setup-main-page,
  .company-list-setup-mainpage,
  .company-setting-setup-mainpage {
    margin-bottom: 10%;
  }

  .credential-field-width {
    width: 60%;
  }

  .setup-height-div {
    min-height: 74vh;
  }

  .setting-setup-width {
    width: 75%;
  }

  .history-expanded-block .pf-primary-btn span:first-child {
    display: none;
  }

  .landing-block-menu {
    font-size: 9px;
  }

  /* .middle-header-col {
    width: 14%;
  } */

  .response-btn-div {
    display: inline;
  }

  .setting-width-for-media {
    width: 50% !important;
  }

  .update-client-btn-div,
  .client-search-input-col,
  .client-search-btn-col {
    display: inline;
  }

  .pf-sidebar-menu {
    font-size: 14px;
  }

  .finance-company-name,
  .pf-setting-title,
  .vehicles-title {
    font-size: 12px;
  }

  /* .nowcerts-sso-page .content-wrapper {
    min-height: 80vh;
  }

  .linkuser-page .content-wrapper {
    min-height: 80vh;
  } */
}

@media (max-width: 992px) and (min-width: 769px) {
  .carousel-card {
    width: 70%;
  }
  .first-carousel-card{
    width: 100% !important;
  }
    .visible{
      width: 100% !important;
    }
    .landing-search{
      width: 90% !important;
    }
  .carousel-logo{
     width: 50px;
     margin-top: 5px;
  }
  .content-wrapper {
    padding-top: 8%;
  }
  .common-setting-main-page .content-wrapper {
    padding-top: 20%;
  }
  /* .content-wrapper {
    padding-top: 8% !important;
    padding-bottom: 7% !important;
  } */

  .fenris-feedback-panel {
    max-width: 50% !important;
  }

  .login-box,
  .register-box {
    width: 45%;
  }

  .display-for-desktop-flex {
    display: none !important;
  }

  .back-btn-div {
    position: absolute;
    top: 0;
  }

  .main-content-row {
    min-height: 72vh;
  }

  .pf-sidebar-menu {
    font-size: 14px;
  }

  /* .middle-header-col {
    width: 20%;
  } */

  .update-client-btn-div,
  .client-search-input-col,
  .client-search-btn-col {
    display: inline;
  }

  .response-btn-div {
    display: inline;
  }

  .display-for-desktop {
    display: none !important;
  }

  .display-for-mobile {
    display: block !important;
  }

  .display-for-mobile-flex {
    display: flex !important;
  }

  .expanded-row-button-block {
    flex-direction: row;
  }

  .expanded-row-button-block button {
    width: 100% !important;
    margin: 10px 2px !important;
  }

  .mobile-view-header-menu img {
    height: 20px;
    margin-right: 5px;
  }

  .mobile-view-header-menu {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }

  .setup-right-side {
    margin-left: 0px;
    margin-top: 20px !important;
  }

  .ams-agency-setup-main-page,
  .company-list-setup-mainpage,
  .company-setting-setup-mainpage {
    margin-bottom: 10%;
  }

  .credential-field-width {
    width: 70%;
  }

  .company-list-setup-mainpage,
  .company-setting-setup-mainpage {
    min-height: 20vh;
  }

  .setting-setup-width {
    width: 75%;
  }
}

@media (max-width: 768px) and (min-width: 200px) {
  .carousel-card {
    width: 90%;
  }
.first-carousel-card{
  width: 100% !important;
}
  .visible{
    width: 100% !important;
  }
  .content-img-row, .carousel-continue-btn-div{
    text-align: center;
    justify-content: center;
  }
  .carousel-logo{
     width: 50px;
     margin-top: 5px;
  }
  .landing-page-blocks {
    width: 100%;
    margin-bottom: 17px;
  }
  .content-wrapper {
    padding-top: 8%;
  }
  .common-setting-main-page .content-wrapper {
    padding-top: 12%;
  }
  /* .wrapper-below-div {
    margin-top: 12% !important;
  } */

  .email-panel {
    width: 90% !important;
    left: 20px !important;
  }

  .quote-response-block .btn-div {
    padding: 0 !important;
  }

  .continue-microsoft-div,
  .continue-google-div {
    text-align: start;
  }

  .social-link-block {
    display: flex;
  }

  .social-link-block-nowcerts {
    align-items: center;
    justify-content: center;
  }

  .sepearte-page-header-div {
    text-align: start;
    padding: 0 !important;
  }

  .remove-policy-icon {
    position: absolute;
    top: -4px;
    right: 4px;
  }

  .p-card .p-card-content {
    padding: 5px 0px !important;
  }

  .p-card.p-component:hover {
    border: 1px solid #6f2d80;
  }

  .p-card.p-component {
    border: 1px solid #fff;
  }

  .p-card-body {
    padding: 0.25rem 10px !important;
  }

  /* .content-wrapper {
    padding-top: 2% !important;
    padding-bottom: 7% !important;
  } */

  .policy-name-tab {
    display: flex;
    overflow: scroll;
    padding: 0;
  }

  .policy-name-span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .response-btn-div .pf-primary-btn {
    width: 100% !important;
  }

  .fenris-feedback-panel {
    max-width: 50% !important;
  }

  .google-box {
    text-align: start !important;
  }

  .setup-right-side {
    margin-left: 0px;
    margin-top: 20px !important;
  }

  .setup-info-div {
    width: 100%;
  }

  .company-list-setup-mainpage,
  .company-setting-setup-mainpage {
    min-height: 10vh;
  }

  .ams-agency-setup-main-page,
  .company-list-setup-mainpage,
  .company-setting-setup-mainpage {
    margin-bottom: 10%;
  }

  .setting-setup-width {
    width: 100%;
  }

  .credential-field-width {
    width: 80%;
  }

  .sidebar-menu-title {
    display: none;
  }

  .profile-border {
    border-right: 0;
  }

  .back-btn-div {
    position: absolute;
    top: 0;
  }

  .display-for-mobile-flex {
    display: flex !important;
  }

  .history-expanded-block .pf-primary-btn span:first-child {
    display: none;
  }

  .history-search-row {
    padding: 25px 5px;
  }

  .profile-box {
    padding: 10px;
  }

  .history-searchbtn-div {
    text-align: right;
  }

  .update-client-btn-div {
    text-align: end;
    display: block;
  }

  .client-search-input-col,
  .client-search-btn-col {
    display: block;
  }

  .login-box,
  .register-box {
    width: 98%;
  }

  /* .middle-header-col {
    width: 44% !important;
  } */

  .history-details-font {
    font-size: 11px;
  }

  .partner-logo-and-span {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .pf-sidebar-menu {
    text-align: center;
    padding: 10px !important;
  }

  .history-table tbody {
    font-size: 12px;
  }

  .wrapper-main-row {
    padding: 10px;
  }

  .terms-box {
    width: 100%;
  }

  .display-for-desktop {
    display: none !important;
  }

  .display-for-desktop-flex {
    display: none !important;
  }

  .display-for-mobile {
    display: block !important;
  }

  .expanded-row-button-block {
    flex-direction: row;
    display: block;
  }

  .expanded-row-button-block button {
    width: 100% !important;
    margin: 10px 2px !important;
    font-size: 12px !important;
  }

  .details-border {
    border-right: none;
  }

  .header-ig-logo {
    height: 25px;
    width: auto;
  }

  .mobile-view-header-menu img {
    height: 20px;
    margin-right: 5px;
  }

  .mobile-view-header-menu {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }

  .main-content-row {
    min-height: 71vh;
  }

  .copyright-block,
  .footer-copyright-div {
    text-align: center;
  }

  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last,
  span.p-paginator-pages button {
    min-width: 2rem !important;
    height: 2rem !important;
  }
}

@media (max-width: 426px) and (min-width: 200px) {
  .pf-settings-main-page .pf-primary-btn {
    font-size: 12px !important;
    padding: 2px !important;
    display: flex !important;
    align-items: center !important;
  }
  .content-wrapper {
    padding-top: 16%;
  }
  .common-setting-main-page .content-wrapper {
    padding-top: 27%;
  }
  /* .content-wrapper {
    padding-top: 16% !important;
    padding-bottom: 12% !important;
  } */

  /* .common-setting-main-page .content-wrapper,
  .admin-main-page .content-wrapper,
  .new-quote-main-page .content-wrapper,
  .pf-settings-main-page .content-wrapper,
  .profile-main-page .content-wrapper,
  .startup-page .content-wrapper,
  .pf-history-main-page .content-wrapper,
  .pf-saved-quote-main-page .content-wrapper,
  .acord-smart-scan-page .content-wrapper,
  .nowcerts-coi-scan-page .content-wrapper {
    padding-top: 5% !important;
    padding-bottom: 12% !important;
  } */

  .fenris-feedback-panel {
    max-width: 90% !important;
  }

  .setting-setup-width {
    width: 100%;
  }

  .company-list-setup-mainpage,
  .company-setting-setup-mainpage {
    min-height: 10vh;
  }

  .credential-field-width {
    width: 100%;
  }

  .ams-agency-setup-main-page,
  .company-list-setup-mainpage,
  .company-setting-setup-mainpage {
    margin-bottom: 25%;
  }

  .response-btn-div {
    display: inline;
  }

  .accept-checkbox {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .header-ig-logo,
  .header-momentum-logo {
    width: 100%;
  }

  .client-and-policy-title-row {
    font-size: 12px;
  }

  .add-apps-menu {
    display: none !important;
  }
}

/* media query end */
.coi-scan-middle-header-col.active {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin-bottom: 0;
  border-radius: 10px 10px 0px 0px;
}

.coi-scan-middle-header-col {
  border-radius: 10px;
  padding: 10px 7px;
  display: inline-block;
  margin-right: 10px;
  width: auto;
  margin-bottom: 10px;
  border: 1px solid #381576;
  border-bottom: 0;
}

.uploadNowcertsDoc25 {
  border: 2px dashed #42526e !important;
  border-radius: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eeeeee !important;
  margin: 0 auto;
  min-height: 65vh;
}

.uploadNowcertscoi {
  border: 2px dashed #42526e !important;
  border-radius: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80% !important;
  background: #eeeeee !important;
  margin: 0 auto;
  min-height: 65vh;
  padding: 10px;
}

.browse-pdf {
  color: #4ab6cc;
  display: block;
  font-size: large;
  border: "solid 1px" !important;
  border-radius: "5px" !important;
  padding: "3px" !important;
}

.coi-newscan {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.coi-newscan .active {
  background-color: #fff;
  color: #77a13b;
}

.coi-info {
  display: flex;
  justify-content: center;
}

.history-refresh-button {
  padding-top: 12px;
  display: flex;
  justify-content: end;
}

.history-refresh-button-start {
  padding-top: 12px;
  display: flex;
  justify-content: start;
}

.show-pdf-icon {
  display: flex;
  font-size: 12px;
  padding: 10px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background: white;
  width: 50%;
}

.drag-and-drop {
  color: black;
  font-size: large;
  font-weight: 800;
}

.uploaded {
  color: #268a6c;
}

.preview-icon {
  margin-right: 15px;
}

.info {
  font-size: 14px;
  font-weight: bold;
  border-radius: 6px;
  padding: 6px;
  width: 56%;
}

.smart-scan-dropdown {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  width: fit-content;
}
.smart-scan-acord-dropdown {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  width: fit-content;
  padding: 10px !important;
  border-radius: 8px !important;
  border-color: #dfe1e6 !important;
  color: #5c5a5a !important;
}

.smartscan-tab {
  border-bottom: 1px solid #233646;
  margin-top: 10px;
  padding: 5px 11px 10px 15px;
}

.smartscan-tab .policy-name-span {
  border-bottom: none !important;
  padding: 10px 15px;
  border-radius: 5px 5px 0px 0px;
  font-weight: bold;
  border: 1px solid #233646;
  color: #233646;
}

.smartscan-block {
  box-shadow: 0 0 20px rgb(51 51 51 / 10%);
  transition: 0.1s border, 0.3s transform, 0.3s -webkit-transform;
  margin: 10px 3px;
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: #fff;
}

.smartscan-block:hover {
  border: 1px solid rgba(71, 18, 125, 1);
}

.smartscan-text {
  margin-top: 11px !important;
}

.coi-download-icon {
  display: flex;
}

.browse {
  color: rgb(74, 182, 204);
  display: block;
  font-size: large;
  border: 1px solid;
  border-radius: 5px;
  padding: 3px;
}

.filename {
  margin-left: 10px;
}

.page-loading {
  padding-top: 15px;
}

.onsubmit-info-show {
  display: flex;
  justify-content: center;
}

.smart-scan-dropdown {
  padding: 10px !important;
  border-radius: 8px !important;
  border-color: #dfe1e6 !important;
  color: #5c5a5a !important;
}


.use-this-value {
  right: 1%;
}

.update-ams-data {
  display: flex;
  justify-content: end;
}

.css-1k5ez1w[data-disabled][data-checked] {
  background-color: #00875a !important;
}

.feedback-container {
  display: flex;
}

.thumbs-up-btn,
.thumbs-down-btn {
  font-size: 24px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.fenris-feedback-panel {
  min-height: 70px !important;
  max-width: 20%;
}

.feedback-thumbs-up {
  font-size: 28px !important;
  margin-right: 10px;
  cursor: pointer;
}

.feedback-thumbs-up.active {
  color: #00875a;
}

.feedback-thumbs-down {
  font-size: 28px !important;
  margin-right: 10px;
  cursor: pointer;
}

.feedback-thumbs-down.active {
  color: #b84c4c;
}

.fix-width-support {
  min-width: 500px !important;
}

.history-refresh-button-start {
  padding-top: 12px;
  display: flex;
  justify-content: end;
}

.fenrisfeedback {
  display: flex;
  justify-content: center;
}

.fix-width-support {
  min-width: 500px !important;
}

/* Vehicles  Css */
#vehicles-client-search {
  position: absolute !important;
  height: fit-content !important;
  max-height: 200px;
  min-height: 35px;
  margin-top: 0px;
  overflow: auto;
  border-radius: 0 0 5px 5px;
  z-index: 999;
}

#vehicles-client-search > div {
  min-height: 35px;
  padding: 5px;
  display: flex;
  align-items: center;
  transition: 0.1s background;
}

.vehicle-box-height {
  height: 52vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.vehicle-search-area {
  border: 1px solid;
  height: 32px;
  padding-left: 5px;
}

.vehicles-title {
  padding: 10px;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pf-scan-balance {
  display: flex;
  align-items: center;
}

.balance-border {
  border: 1px solid red !important;
  border-radius: 6px;
}

.pf-scan-icon-landing-page {
  height: 50px;
  margin-bottom: 10px;
}

.pf-scan-darg-and-drop {
  color: black;
  font-size: large;
  font-weight: 600;
}

.vehicle-current-balance {
  display: flex;
  justify-content: end;
}

.preview-vehicle-box-height {
  height: 78vh;
  overflow-y: auto;
  overflow-x: hidden;
}

 .fenris-multi-dropdown  {
    padding: 6px !important;
    border-radius: 8px !important;
    border-color: #dfe1e6 !important;
    color: #5c5a5a !important;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    left: 15%;
    border: 1px solid #00875a !important
 }
 .fenris-switch .p-inputswitch {
  height: 1.50rem;
}
.fenris-switch.p-inputswitch .p-inputswitch-slider:before
 {
  width: 1rem !important;
  height: 1rem !important;
}
.fenris-switch .p-inputswitch-slider:before {
  top: 60%;
}
.naics-show-balance{
  display: flex;
    justify-content: end;
}
.scan-file-name {
  text-align: start;
  padding: 4px 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  min-height: 30px;
}
.appetite-img{
  width: 125px;
}
.invoice-img{
width: 100% !important;
}